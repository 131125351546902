// https://github.com/apertureless/vue-chartjs
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  name: "BarChart",
  extends: Bar,
  mixins: [reactiveProp],
  props: ['chartData', 'options'],
  mounted () {
    var chartData = this.chartData;
    if (chartData === undefined) {
      chartData = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [
          {
            label: 'GitHub Commits',
            backgroundColor: '#f87979',
            data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
          }
        ]
      }
    }
    var options = this.options;
    if (options === undefined) {
      options = {
          scales: {
              yAxes: [{
                  ticks: {
                      beginAtZero: true
                  }
              }]
          }
      }
    }
    this.renderChart(chartData, options)
  },
  methods: {
    getRandomInt () {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    }
  }
}