<template>
<div class="content-i ng-scope" ui-view="mainContent">
   
    <div class="content-box ng-scope disable-scrollbars">
        <div class="row">
            <div class="col-sm-12 col-md-8 col-lg-9 pay-run-section mt-4">
                <div class="pay-run-header">
                    <h4>Payroll Summary</h4>
                   <div class="form-group">
                                    <select
                                        class="
                                        customSelectIcon form-control indigo theme--dark v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--small"
                                        name="payroll_period"
                                        style="height: 28px !important;padding-right: 19px;"
                                        @change="changePayPeriod($event)"
                                        v-model="payPeriodSelected"
                                    >
                                        <option value="Weekly" selected="selected">
                                        Weekly
                                        </option>
                                        <option value="Fortnightly">
                                        Fortnightly
                                        </option>
                                        <option value="Monthly">Monthly</option>
                                    </select>
                                </div>
                </div>
                <div class=" element-box">
                    <div class="row">                    
                        <div class="col-lg-3 col-sm-12">
                            <h6 class="pay-roll-top-header-heading">Gross - Year to date</h6>
                            <h6>${{roundNumber(totalPyaRollInYearToDate)}}</h6>
                        </div>
                        <div class="col-lg-3 col-sm-12">
                            <h6 class="pay-roll-top-header-heading">Gross - Last month</h6>
                            <h6>${{roundNumber(totalPayrollInLastMonth)}}</h6>
                        </div>
                        <div class="col-lg-3 col-sm-12">
                            <h6 class="pay-roll-top-header-heading">Next Pay Run Date</h6>
                            <h6>{{this.nextLodgementDueDate}}</h6> <!--"MMM DD" -->
                        </div>
                        <div class="col-lg-2 col-sm-12">
                            <h6 class="pay-roll-top-header-heading">Next Pay Due in</h6>
                            <h6 v-bind:class="(computedNextPayDueIn == 'Today' ? 'pay-run-today-text' : (computedNextPayDueIn == 'Overdue' ? 'pay-run-overdue-text' : ''))">
                                {{computedNextPayDueIn}}
                            </h6>
                        </div>
                  </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 pay-run-section mt-4">
                        <div class="pay-run-header mb-2">
                            <h4>Pay Runs</h4>
                            <div class="d-flex">
                                <v-menu offset-y v-model="newTaskMenu">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="indigo ml-3" dark v-bind="attrs" v-on="on" small>
                                            New Task
                                            <v-icon right dark v-if="!newTaskMenu">
                                                mdi-chevron-right 
                                            </v-icon>
                                            <v-icon right dark v-if="newTaskMenu">
                                                mdi-chevron-down 
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item @click="goRunPayroll()">
                                            <v-list-item-title color="#fff">Process Payroll</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="goNewEmployee()">
                                            <v-list-item-title>New Employee</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="goAddLeave()">
                                            <v-list-item-title>Add Leave</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="goViewReport()">
                                            <v-list-item-title>View Report</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                    </v-menu>
                                   
                            </div>
                             
                        </div>
                        <div class="element-box">
                            <div class='table-responsive' style="padding-bottom: 0px;">
                                <table id="payruns" class="table table-striped" style="padding: 0px;">
                                    <thead>
                                        <tr>
                                            <th v-for="(item) in listColumn" :key="item.id" v-show="item.isActivated == true" v-bind:class="(item.name == 'Payment Date') ? 'text-center':(item.name == 'Gross')?'text-right' :'text-left'">
                                                <span>{{item.name}}</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item) in listPayRoll" :key="item.id" class="animatedUp ng-scope">
                                            <td v-for="(i) in listColumn" :key="i.id"  v-bind:class="(i.name == 'Payment Date' || i.name == 'Total Amount') ? 'text-center': 'text-left'">
                                                <div v-if="i.name == 'Pay Period'" >
                                                    <span class="ng-binding text-left"> {{item.payRunPeriod}} Ending {{formatDate(item.endPayPeriod)}}</span>
                                                    <span class="smaller lighter ml-0 ng-binding text-right" style="padding-left: 10px;"></span>
                                                </div>
                                                <div v-if="i.name == 'Gross'" class="text-right">
                                                    <span class="ng-binding">${{roundNumber(item.totalEarnings)}}</span>
                                                </div>
                                                <div v-if="i.name == 'Paid'">
                                                    <span class="ng-binding"> {{formatDate(item.paymentDate)}}</span>
                                                </div>
                                                <div v-if="i.name == 'Status'">
                                                    <span v-if="item.statusPayroll == 'Done'" class="badge status ng-binding bg-yellow ">Done</span>
                                                    <span v-else v-bind:class="'badge status ng-binding '+ (item.statusPayroll == 'Draft' ? 'bg-grey' : (item.statusPayroll == 'Pushed' ? 'bg-blue' : (item.statusPayroll == 'Error' ? 'bg-red' :(item.statusPayroll == 'Lodged' ? 'bg-green' : 'bg-pink'))))">{{item.statusPayroll}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-show="listPayRoll.length === 0">
                                            <td colspan="4" style="text-align: center;">No pay runs for last 3 months</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-lg-12 pay-run-section mt-4">
                        <div class="pay-run-header mb-2">
                            <h4>Pay history</h4>
                        </div>
                        <div class="element-box d-flex">
                            <div class="col-lg-3 col-sm-12">
                                <h6 class="pay-roll-top-header-heading">Gross - Last month</h6>
                                <h6>${{roundNumber(totalPayrollInLastMonth)}}</h6>
                            </div>
                            <div class="col-lg-3 col-sm-12">
                                <h6 class="pay-roll-top-header-heading">Gross - Year to date</h6>
                                <h6>${{roundNumber(totalPyaRollInYearToDate)}}</h6>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-lg-12 pay-run-section mt-4">
                        <div class="pay-run-header mb-2">
                            <h4>Leave to Approve</h4>
                        </div>
                        <div class="element-box">
                            <div class='table-responsive' style="padding-bottom: 0px;">
                                <table class="table table-striped" style="padding: 0px;"> 
                                    <thead>
                                        <tr>
                                            <th>
                                                <span v-on:click="sort('employeeName', false)">Employee</span>
                                            </th>
                                            <th class="text-center">
                                                <span v-on:click="sort('startDate', true)">Date</span>
                                            </th>
                                            <th class="text-center">
                                                <span v-on:click="sort('type', false)">Type</span>
                                            </th>
                                            <th>
                                                <span v-on:click="sort('status', false)">Status</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item) in listLeaveSub" :key="item.id" :ref="item.id" class="animatedUp ng-scope">
                                            <td>
                                                <span class="ng-binding">{{item.employeeName}}</span>
                                            </td>
                                            <td style="min-width: 140px;" class="text-center">
                                                <span class="ng-binding">{{formatDate(item.startDate)}} - {{formatDate(item.endDate)}}</span>
                                            </td>
                                            <td class="text-center">
                                                <div v-bind:class="(item.type == 'Sick')?'badge ng-binding badge-sick':'badge ng-binding badge-annual'">{{item.type}}</div>
                                            </td>
                                            <td class="nowrap">
                                                <span v-if="item.status == 'Completing'" class="badge status ng-binding bg-blue">Submitting</span>
                                                <span v-else class="badge status ng-binding " v-bind:class="(item.status == 'Reject')?'bg-grey': (item.status == 'Pending')? 'bg-yellow' : 'bg-green'">{{item.status}}</span>
                                            </td>
                                        </tr>
                                        <tr v-show="listLeaveSub.length === 0">
                                            <td colspan="4" style="text-align: center;">No leave to approve</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-3 ">
                <div class="row mt-4">
                    <div class="col-lg-12">
                        <v-date-picker no-title full-width :events="calendarEvents" event-color="calender-event-dot-margin" color="green"></v-date-picker>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-12">
                        <div class="pay-run-header mb-2">
                            <h4>Last 6 Month Payroll</h4>
                        </div>
                        <div class="bar element-box">
                            <BarChart v-bind:chartData="barchartData" :options="last6MonthChartOptions"  :width="300" :height="200" />
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-12">
                         <div class="pay-run-header mb-2">
                            <h4>Payroll Summary</h4>
                        </div>
                        <div class="element-box doughnut">
                            <PieChart v-bind:chartData="chartData" :options="pieChartOptions" :width="300" :height="272" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import PieChart from '@/components/chart/PieChart.js'
import BarChart from '@/components/chart/BarChart.js'

import * as Session from "@/utils/SessionStore"
import * as Utils from "@/utils/Utils.js";

import {
    GET_INFOR_SUMMARY,
    GET_INFOR_TOP_INCOME,
    GET_LIEST_LEAVE,
    GET_LAST_6_MONTH_PAYROLL_SUMMARY,
    GET_YEARLY_PAYROLL_REPORT,
    GET_PAYROLL_SUMMARY_REPORT,
    GET_LIST_HOLIDAYS,
    GET_LAST_THREE_MONTH_PAYROLL,
    GETLIST_RUNPAYROLL
} from "@/store/actionsType";
import moment from 'moment';

export default {
    name: "Dashboard",
    props: [],
    components: {
        PieChart,
        BarChart
    },
    data() {
        return {
            chartData: "",
            pieChartOptions: {
                responsive: true,
                scales: {
                    xAxes: [{
                        gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                            display: false,
                            drawOnChartArea: false
                        },
                        ticks: {
                            display: false,
                            beginAtZero: true
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                            display: false,
                            drawOnChartArea: false
                        },
                        ticks: {
                            display: false,
                            beginAtZero: true
                        }
                    }]
                },
                legend: {
                    display: true,
                    position: 'bottom',
                    align: 'center',
                    rtl: false,
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 20,
                        bottom: 0
                    }
                },
                tooltips: {
                    'enabled': true
                }
            },
            last6MonthChartOptions: {
                responsive: true,
                legend: {
                    display: false
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 20,
                        bottom: 0
                    }
                },
                tooltips: {
                    'enabled': true
                },
                scales: {
                    yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        callback: function(value) {
                            if(parseInt(value) >= 1000){
                                return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            } else {
                                return '$' + value;
                            }
                        }
                    }
                    }]
                }
            },
            barchartData: "",
            listPayRoll: [],
            //Tab All
            isShowColumn: false,
            listColumn: [
                {
                    id: 0,
                    isActivated: true,
                    name: 'Pay Period',
                    keySort: 'startPayPeriod'
                },
                 {
                    id: 1,
                    isActivated: true,
                    name: 'Gross',
                    keySort: 'totalNetPay'
                },
                {
                    id: 2,
                    isActivated: true,
                    name: 'Paid',
                    keySort: 'paymentDate'
                },
                {
                    id: 3,
                    isActivated: true,
                    name: 'Status',
                    keySort: 'statusPayroll'
                }
            ],
            newTaskMenu: false,
            listLeaveSub: [],
            payPeriodDropDown: false,
            totalPayrollInLastMonth: 0,
            totalPyaRollInYearToDate: 0,
            nextPayRunDate: '',
            listAllHoidays: [],
            calendarEvents:[],
            endPayWeekly: "",
            endPayFortnightly: "",
            endPayMonthly: "",
            nextLodgementDueDate: "",
            selectedPayPeriod: "",
            payPeriodSelected:'',
            payrollListForPayPeriod:[],
            totalOfLastWeeklyPayrun:0,
            totalYearToDateForWeeklyPayrun:0,
            totalOfLastFortnightlyPayrun:0,
            totalYearToDateForFortnightlyPayrun:0,
            totalOfLastMonthlyPayrun:0,
            totalYearToDateForMonthlyPayrun:0,
            totalPayroll:0,
            totalPayrollYearly:0,
            PayPeriodLabel:'',

        };
    },
    created: function () {
        this.$root.$refs.Dashboard = this;
        this.getInforSummary();
        this.getLastThreeMonthRunPayRollList();
        this.getListRunPayRoll();
    },
    methods: {
        goRunPayroll() {
            this.$root.$refs.BaseLayout.clickItemLeftMenu(2);
        },

        goNewEmployee() {
            this.$root.$refs.BaseLayout.clickItemLeftMenu(3);
        },

        goAddLeave() {
            this.$root.$refs.BaseLayout.clickItemLeftMenu(4);
        },

        goViewReport() {
            this.$root.$refs.BaseLayout.clickItemLeftMenu(5);
        },

        changePayPeriod(event) {
            this.payPeriodSelected= event.target.value;
            this.onChangePayPeriod(this.payPeriodSelected);
        },

        // Request API
        getInforSummary() {
            if (Session.has("currenCompanyId")) {
                this.$root.$refs.AppCyrus.start()
                var dt = new Date();
                if((dt.getMonth()+1) >= 7) {
                    var year = dt.getFullYear()
                } else {
                    year = dt.getFullYear() - 1
                }
                var endYear = parseInt(year) + 1
                var modelRequest = {
                    companyId: Session.get("currenCompanyId"),
                    fromDate: year + "-07-01",
                    toDate: endYear + "-06-30",
                }
                this.$store.dispatch(GET_INFOR_SUMMARY, modelRequest)
                    .then((response) => {
                        this.$root.$refs.AppCyrus.finish()
                        this.isRequestInforSummary = true
                        this.chartData = {
                            labels: ['Tax', 'Deduction', 'Net pay', 'Super'],
                            datasets: [{
                                label: '# of Votes',
                                data: [response.totalTax, response.totalDeduction, response.totalNetPay, response.totalSuper],
                                backgroundColor: [
                                    '#FC6E73',
                                    '#61BB4B',
                                    '#2BA8D4',
                                    '#FFDE57'
                                ],
                                borderColor: [
                                    '#FC6E73',
                                    '#61BB4B',
                                    '#2BA8D4',
                                    '#FFDE57'
                                ],
                                borderWidth: 1
                            }]
                        }
                        this.getLat6MonthPayRollSummary();
                        this.getYearlyPayRollReport(); 
                        this.getPayrollSummaryReport()
                    }).catch(() => {
                        this.$root.$refs.AppCyrus.fail()
                    });
            }
        },

        getInforTopIncome() {
            if (Session.has("currenCompanyId")) {
                this.$root.$refs.AppCyrus.start()
                var dt = new Date();
                if((dt.getMonth()+1) >= 7) {
                    var year = dt.getFullYear()
                } else {
                    year = dt.getFullYear() - 1
                }
                var endYear = parseInt(year) + 1
                var modelRequest = {
                    companyId: Session.get("currenCompanyId"),
                    fromDate: year + "-07-01",
                    toDate: endYear + "-06-30",
                    size: 10
                }
               // Logs.json("modelRequest", modelRequest)
                this.$store.dispatch(GET_INFOR_TOP_INCOME, modelRequest)
                    .then((response) => {
                      //  Logs.json("response", response)
                        var listName = []
                        var listData = []
                        var listIncome = []
                        listIncome = response.listIncome
                        if (listIncome.length > 10) {
                            listIncome = listIncome.slice(0, 10)
                        }
                        for (var i = 0; i < listIncome.length; i++) {
                            listName.push(listIncome[i].firstName + " " + listIncome[i].lastName)
                            listData.push(listIncome[i].netPay)
                        }
                        this.$root.$refs.AppCyrus.finish()
                        this.barchartData = {
                            labels: listName,
                            datasets: [{
                                label: 'Top Income',
                                backgroundColor: '#2BA8D4',
                                data: listData
                            }]
                        }
                        this.getLastThreeMonthRunPayRollList();
                        this.getListLeave();
                        this.getListRunPayRoll();
                    }).catch(() => {
                        this.$root.$refs.AppCyrus.fail()
                    });
            }

        },

        getLat6MonthPayRollSummary() {
            if (Session.has("currenCompanyId")) {
                this.$root.$refs.AppCyrus.start()
                var modelRequest = {
                    companyId: Session.get("currenCompanyId")
                }
              //  Logs.json("modelRequest", modelRequest)
                this.$store.dispatch(GET_LAST_6_MONTH_PAYROLL_SUMMARY, modelRequest)
                .then((response) => {
                    var listName = []
                    var listData = []
                    for (var i = 0; i < response.length; i++) {
                        listName.push(response[i].month)
                        listData.push(response[i].netPay)
                    }
                    this.$root.$refs.AppCyrus.finish()
                    this.barchartData = {
                        labels: listName,
                        datasets: [{
                            label: 'Top Income',
                            backgroundColor: '#2BA8D4',
                            data: listData
                        }],
                        legend : {
                            display: false
                        }
                    }
                    this.getLastThreeMonthRunPayRollList();
                    this.getListLeave();
                    this.getListHolidays();
                    this.getListRunPayRoll();
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
            }
        },

         getYearlyPayRollReport() {
            if (Session.has("currenCompanyId")) {
                this.$root.$refs.AppCyrus.start()
                var modelRequest = {
                    companyId: Session.get("currenCompanyId")
                }
             //   Logs.json("modelRequest", modelRequest)
                this.$store.dispatch(GET_YEARLY_PAYROLL_REPORT, modelRequest)
                .then((response) => {
                    this.$root.$refs.AppCyrus.finish();
                    this.totalPayrollInLastMonth = response.totalPayrollLastMonth;
                    this.totalPyaRollInYearToDate = response.totalPayrollYearToDate;
                    this.nextPayRunDate = response.nextPayRunDate;
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
            }
        },
         getPayrollSummaryReport() {
            if (Session.has("currenCompanyId")) {
                this.$root.$refs.AppCyrus.start()
                var modelRequest = {
                    companyId: Session.get("currenCompanyId")
                }
                // this.totalOfLastWeeklyPayrun = 25000
                // this.totalYearToDateForWeeklyPayrun = 85136.00
                // this.totalOfLastFortnightlyPayrun = 4000
                // this.totalYearToDateForFortnightlyPayrun = 50136.00
                // this.totalOfLastMonthlyPayrun = 7002
                // this.totalYearToDateForMonthlyPayrun = 70136.00
             //   Logs.json("modelRequest", modelRequest)
                this.$store.dispatch(GET_PAYROLL_SUMMARY_REPORT, modelRequest)
                .then((response) => {
                    this.$root.$refs.AppCyrus.finish();
                    this.totalOfLastWeeklyPayrun = response.totalOfLastWeeklyPayrun
                    this.totalYearToDateForWeeklyPayrun = response.totalYearToDateForWeeklyPayrun
                    this.totalOfLastFortnightlyPayrun = response.totalOfLastFortnightlyPayrun
                    this.totalYearToDateForFortnightlyPayrun = response.totalYearToDateForFortnightlyPayrun
                    this.totalOfLastMonthlyPayrun = response.totalOfLastMonthlyPayrun
                    this.totalYearToDateForMonthlyPayrun = response.totalYearToDateForMonthlyPayrun
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
            }
        },

        // Request API
        getLastThreeMonthRunPayRollList() {
            if (Session.has("currenCompanyId")) {
                this.$root.$refs.AppCyrus.start()
                var modelRequest = {
                    companyId: Session.get("currenCompanyId")
                }
                this.$store.dispatch(GET_LAST_THREE_MONTH_PAYROLL, modelRequest)
                .then((response) => {
                    this.$root.$refs.AppCyrus.finish()
                    this.listPayRoll = response.reverse()
                    // this.listPayRoll = this.payrollListForPayPeriod.filter((listPayRollData) => (listPayRollData.payRunPeriod).toLowerCase()===(this.payPeriodSelected).toLowerCase());
                    
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
            }
        },

        getListRunPayRoll() {
            if (Session.has("currenCompanyId")) {
                this.$root.$refs.AppCyrus.start()
                var modelRequest = {
                    companyId: Session.get("currenCompanyId"),
                    fromDate: this.getCurrentYear() + "-07-01",
                    toDate: this.getCurrentYear() + 1 + "-06-30",
                }
                this.$store.dispatch(GETLIST_RUNPAYROLL, modelRequest)
                    .then((response) => {
                        this.$root.$refs.AppCyrus.finish()
                        this.onGetListRunPayRollSuccess(response.reverse())
                    }).catch(() => {
                        this.$root.$refs.AppCyrus.fail()
                    });
            } else {
                if (this.$root.$refs.BaseLayout != undefined) {
                    //this.$root.$refs.BaseLayout.logout()
                }
            }
        },

        onGetListRunPayRollSuccess(listPayRoll) {
            this.endPayWeekly = ""
            this.endPayFortnightly = ""
            this.endPayMonthly = ""
            for (var i = 0; i < listPayRoll.length; i++) {
                if (listPayRoll[i].payRunPeriod == 'Weekly') {
                    if (this.endPayWeekly == '') {
                        this.endPayWeekly = listPayRoll[i].endPayPeriod
                    }
                    if (!this.checkDate(this.endPayWeekly, listPayRoll[i].endPayPeriod)) {
                        this.endPayWeekly = listPayRoll[i].endPayPeriod
                    }
                } else if (listPayRoll[i].payRunPeriod == 'Fortnightly') {
                    if (this.endPayFortnightly == '') {
                        this.endPayFortnightly = listPayRoll[i].endPayPeriod
                    }
                    if (!this.checkDate(this.endPayFortnightly, listPayRoll[i].endPayPeriod)) {
                        this.endPayFortnightly = listPayRoll[i].endPayPeriod
                    }
                } else if (listPayRoll[i].payRunPeriod == 'Monthly') {
                    if (this.endPayMonthly == '') {
                        this.endPayMonthly = listPayRoll[i].endPayPeriod
                    }
                    if (!this.checkDate(this.endPayMonthly, listPayRoll[i].endPayPeriod)) {
                        this.endPayMonthly = listPayRoll[i].endPayPeriod
                    }
                }
            }

            this.onChangePayPeriod(Session.get("payPeriod"));
        },

        onChangePayPeriod(payPeriod) {
            var endPayPeriod = "";
            this.selectedPayPeriod = payPeriod;
            this.payPeriodSelected =payPeriod;
            
            switch (payPeriod) {
                case "Weekly":
                    if (this.endPayWeekly != "") {
                        endPayPeriod = new Date(this.endPayWeekly);
                        endPayPeriod.setDate(endPayPeriod.getDate() + 7);
                    }
                    this.totalPayroll = this.totalOfLastWeeklyPayrun;
                    this.totalPayrollYearly = this.totalYearToDateForWeeklyPayrun;
                    this.PayPeriodLabel = 'Week'
                break;
                case "Fortnightly":
                    if (this.endPayFortnightly != "") {
                        endPayPeriod = new Date(this.endPayFortnightly);
                        endPayPeriod.setDate(endPayPeriod.getDate() + 14);
                    }
                    
                    this.totalPayroll = this.totalOfLastFortnightlyPayrun;
                    this.totalPayrollYearly = this.totalYearToDateForFortnightlyPayrun;
                     this.PayPeriodLabel = 'Fortnight';
                break;
                case "Monthly":
                    if (this.endPayMonthly != "") {
                        endPayPeriod = new Date(this.endPayMonthly);
                        endPayPeriod.setDate(endPayPeriod.getDate() + 30);
                    }
                    this.totalPayroll = this.totalOfLastMonthlyPayrun;
                    this.totalPayrollYearly = this.totalYearToDateForMonthlyPayrun;
                     this.PayPeriodLabel = 'Month'
                break;
            }
            
            if (endPayPeriod != "") {
                this.nextLodgementDueDate =  moment(endPayPeriod).format("DD/MM/YYYY")
            } else {
                endPayPeriod = this.getPayRollStartDate(payPeriod);
                this.nextLodgementDueDate = moment(endPayPeriod).format("DD/MM/YYYY")
            }
        },

        getPayRollStartDate(payPeriod) {
            var payrollStartDate = Session.get("payrollStartDate");
            var date = new Date(payrollStartDate);
            switch (payPeriod) {
                case "Weekly":
                    date.setDate(date.getDate() + 6);
                break;
                case "Fortnightly":
                    date.setDate(date.getDate() + 13);
                break;
                case "Monthly":
                    var mounthMonthly = date.getMonth() + 1;
                    var yeaMonthly = date.getFullYear();
                    var numberDay = new Date(yeaMonthly, mounthMonthly, 0).getDate() - 1;
                    date.setDate(date.getDate() + numberDay);
                break;
                case "Quarterly":
                    date.setMonth(date.getMonth() + 3);
                break;
            }

            var day = date.getDate();
            var mount = date.getMonth() + 1;
            var year = date.getFullYear();

            if (day.toString().length == 1) {
                day = "0" + day;
            }
            if (mount.toString().length == 1) {
                mount = "0" + mount;
            }
            return year + "-" + mount + "-" + day;
        },

        getTimeRunPayroll(payRunPeriod, endPayPeriod) {
            var startDay = new Date(endPayPeriod);
            switch (payRunPeriod) {
                case "Weekly":
                    startDay.setDate(startDay.getDate() - 6);
                break;
                case "Fortnightly":
                    startDay.setDate(startDay.getDate() - 13);
                break;
                case "Monthly":
                    var mounth = startDay.getMonth() + 1;
                    var year = startDay.getFullYear();
                    var numberDay = new Date(year, mounth, 0).getDate() - 1;
                    startDay.setDate(startDay.getDate() - numberDay);
                break;
                case "Quarterly":
                    startDay.setMonth(startDay.getMonth() - 3);
                break;
            }
            this.nextLodgementDueDate = this.formatDate(startDay);
        },

        checkDate(date1, date2) {
            var d1 = new Date(date1);
            var d2 = new Date(date2);
            if (d1.getTime() > d2.getTime()) {
                return true
            }
            return false
        },

        getCurrentYear() {
            var dt = new Date()
            if ((dt.getMonth() + 1) >= 7) {
                var currentYear = dt.getFullYear()
            } else {
                currentYear = dt.getFullYear() - 1
            }
            return parseInt(currentYear)
        },

        formatDate(inputDate, dateFormat) {
            var date = moment(inputDate);
            dateFormat = dateFormat || "DD/MM/YYYY";

            if(date.isValid()) {
                return moment(inputDate).format(dateFormat);
            } else {
                return "-";
            }
        },

        roundNumber(number) {
            return Utils.roundNumber(number)
        },

        getListLeave() {
            if (Session. has("currenCompanyId")) {
                this.$root.$refs.AppCyrus.start()
                this.$store.dispatch(GET_LIEST_LEAVE, Session.get("currenCompanyId"))
                .then((response) => {
                    this.$root.$refs.AppCyrus.finish();
                    this.listLeaveSub = [];
                    for (var i = 0; i < response.length; i++) {
                        if(response[i].status != "Completed") {
                            this.listLeaveSub.push(response[i])
                        }
                    }
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
            }
        },

        getListHolidays() {
            this.$root.$refs.AppCyrus.start()
            var year = new Date().getFullYear()
            var request = {
                years: [
                    year
                ]
            }
            this.$store.dispatch(GET_LIST_HOLIDAYS, request)
            .then((response) => {
                this.$root.$refs.AppCyrus.finish()
                this.listAllHoidays = []
                this.listAllHoidays = response.records;

                var holidays = [];
                for(var i = 0; i < this.listAllHoidays.length; i++) {
                    holidays.push(this.listAllHoidays[i].date.substr(0, 10))
                }
                this.calendarEvents = holidays;
            }).catch(() => {
                this.$root.$refs.AppCyrus.fail()
            });
        },
    },
    computed: {
        computedNextPayDueIn: function() {
            var startDate = moment(this.nextLodgementDueDate, "DD/MM/YYYY");
            if (startDate.isValid()) {
                var endDate = moment(new Date());
                var difference = startDate.diff(endDate, "days");
                if (difference == 0) {
                    return "Today";
                } else if (difference < 0) {
                    return "Overdue";
                } else {
                    return difference + " days";
                }
            } else {
                return "-";
            }
        }
    }
}
</script>

<style scoped>
    select.customSelectIcon.form-control{
        background: #FFF url(/assets/img/icon_dropdown_white.png) no-repeat right 0.25rem center;
        background-size: 13px 9px;
    }
  
</style>
