// https://github.com/apertureless/vue-chartjs
import { Pie, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
	name: 'PieChart',
	extends: Pie,
	mixins: [reactiveProp],
	props: ['chartData', 'options'],
	mounted() {
		var chartData = this.chartData;
		var options = this.options;
		this.renderChart(chartData, options);
	},
	methods: {
		getRandomInt() {
			return Math.floor(Math.random() * (50 - 5 + 1)) + 5
		}
	}
}
